import { Link } from 'gatsby'
import { get } from 'lodash'
import React from 'react'
import { Fade, Flip } from 'react-reveal'

import {
  Button,
  Content,
  Container,
  Heading,
  Section,
} from 'react-bulma-components'
import {
  Base,
  BasePreview,
  HtmlContent,
} from 'components/common'
import Hero from 'components/hero'

export default function PlayTherapyTemplate(props) {
  const {
    content,
    heroBottomHeading,
    heroTopHeading,
    heroTopImage,
    previewMode,
  } = props

  const BaseComponent = previewMode ? BasePreview : Base

  const heroTopBackground = get(heroTopImage, 'childImageSharp.fluid.src')
    || heroTopImage.value|| null

  return (
    <BaseComponent>
      <Hero backgroundImage={heroTopBackground} gradient size="large">
        <Container>
          <Flip bottom>
            <Content size="medium">
              <Heading className="has-shadow has-text-white" size={1}>
                {heroTopHeading}
              </Heading>
            </Content>
          </Flip>
        </Container>
      </Hero>
      <Fade cascade>
        <Section>
          <Container className="padding-vertical-32">
            <Content size="medium">
              {content && <HtmlContent content={content} />}
            </Content>
          </Container>
        </Section>
      </Fade>
      <Hero className="has-zigs-footer-info reversed has-opacity-4">
        <Container>
            <Content className="has-text-centered" size="small">
              <Heading size={2}>
                {heroBottomHeading}
              </Heading>
              <Button
                color="danger"
                rounded
                renderAs={Link}
                size="large"
                style={{ marginTop: 32 }}
                to="/contact"
              >
                Get in touch
              </Button>
            </Content>
        </Container>
      </Hero>
    </BaseComponent>
  )
}
